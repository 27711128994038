import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle61 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        BTW en accijns terugvorderbaar op rode diesel?

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage61.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                05-12-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            Rode diesel
            </span>
            , goed te herkennen aan zijn rode kleur, is een vorm van brandstof die voornamelijk wordt gebruikt in {" "}
            <span style={{color: "#9c29ff"}}>
            agrarische-, maritieme- en industriële sectoren.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In {" "}
            <span style={{color: "#9c29ff"}}>
            Nederland en veel andere Europese landen    {" "}
            </span>
            is rode diesel momenteel{" "}
            <span style={{color: "#9c29ff"}}>
            verboden voor de wegtransport sector.
            </span>
            {" "}
            <span style={{color: "#2b5baa"}}>
            België     {" "}
            </span>
            is een van de weinige landen die dit wel toestaat, maar uitsluitend als{" "}
            <span style={{color: "#2b5baa"}}>
            brandstof voor het aandrijven van koelmotoren
            </span>
            . Wordt rode diesel gebruikt als reguliere brandstof om een voertuig voort te bewegen, dan kwalificeert de overheid dit als een {" "}
            <span style={{color: "#9c29ff"}}>
            misdrijf 
            </span>
            . Wanneer rode diesel wel correct wordt ingezet in België, dan kan de betaalde{" "}
            <span style={{color: "#2b5baa"}}>
            BTW gewoon worden teruggevorderd      
            </span>
            . Rode diesel is een aantrekkelijke brandstof gezien deze goedkoper is dan gewone diesel vanwege het lagere accijnstarief. Dit lagere tarief {" "}
            <span style={{color: "#2b5baa"}}>
            sluit daarmee echter de mogelijkheid tot verdere Belgische accijns teruggaaf uit.      
            </span>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle61;
