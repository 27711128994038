import {
  Box,
  // Box,
  // Button,
  Container,
  Grid,
  Stack,
  // TextField,
  Typography,
} from "@mui/material";
import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/Add";
import CollapseIcon from "@mui/icons-material/Remove";
// import { Box } from "@mui/system";
// import { height } from "@mui/system";

import MSR from "../../assets/MSR.png";
// import MSR from "../../assets/MSR klein.png";
import vlagBE from "../../assets/BE vlag.png";
import vlagFR from "../../assets/FR vlag.png";
import vlagHU from "../../assets/HU vlag.png";
import vlagES from "../../assets/ES vlag.png";
import vlagSL from "../../assets/SI vlag.png";
import vlagHR from "../../assets/HR vlag.png";
import vlagIT from "../../assets/IT vlag.png";
import Image from "mui-image";

const ExciseRatesForm = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <Typography
          sx={{
            mt: 8,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "40px", md: "55px", xl: "71px" },
            lineHeight: { xs: "55px", md: "70px", xl: "85px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
          }}
        >
          Accijns vergoedingen
        </Typography>
        <Typography
          mb={5}
          sx={{
            mt: 1,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "16px", md: "21px", xl: "21px" },
            lineHeight: { xs: "20px", md: "25.2px", xl: "25.2px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Regular",
          }}
        >
          De actuele dieselolie accijns compensaties op een rijtje.
        </Typography>

        <Grid md={12} container direction="row" item align="center">
          <Grid md={6} xs={12}>
            <Typography
              mt={5}
              mb={5}
              sx={{
                color: "#2B5BAA",
                fontSize: { xs: "24px", md: "30px", xl: "36px" },
                lineHeight: { xs: "22.8px", md: "28.8px", xl: "34.8px" },
                fontFamily: "Century Gothic",
                fontWeight: "bold",
              }}
            >
              Member state of refund (MSR)
            </Typography>
            {/* <img src={MSR} height="450px" alt=""></img> */}
            <Box>
              <Image
                // sx={{ mt: 0 }}
                sx={{
                  // ObjectFit: "cover",
                  maxWidth: "500px",
                }}
                img
                src={MSR}
                alt="cur"
                // height={352}
              />
            </Box>
          </Grid>
          <Grid md={6} xs={12}>
            <Typography
              mt={5}
              mb={5}
              sx={{
                color: "#2B5BAA",
                fontSize: { xs: "24px", md: "30px", xl: "36px" },
                lineHeight: { xs: "22.8px", md: "28.8px", xl: "34.8px" },
                fontFamily: "Century Gothic",
                fontWeight: "bold",
              }}
            >
              Terugvorderbare accijnzen
            </Typography>
            <Grid>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel1" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagBE}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      BE
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,1935000
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.01.24 - heden: € 0,1935000</li>
                      <li>31.03.23 - 31.12.23: € 0,2050665</li>
                      <li>25.03.23 - 30.03.23: € 0,1677884</li>
                      <li>17.03.23 - 24.03.23: € 0,1578884</li>
                      <li>14.03.23 - 16.03.23: € 0,1273384</li>
                      {/* <li>18.02.23 - 24.02.23: € 0,1067384</li> */}
                      {/* <li>19.03.22 - 31.12.22: € 0,0823435</li> */}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel2" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagFR}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      FR
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,1571
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.07.24 - 31.12.24: € 0,1571</li>
                      <li>01.01.24 - 30.06.24: € 0,1571</li>
                      <li>01.07.23 - 31.12.23: € 0,1571</li>
                      <li>01.01.23 - 31.06.23: € 0,1571</li>
                      <li>01.10.22 - 31.12.22: € 0,1570</li>
                      <li>01.07.22 - 30.09.22: € 0,1570</li>
                      <li>01.04.22 - 30.06.22: € 0,1570</li>
                      <li>01.01.22 - 31.03.22: € 0,1570</li>
                      <li>01.10.21 - 31.12.21: € 0,1571</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel3" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagHU}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      HU
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      10,0
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      HUF / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.01.24 - heden: 10,0 HUF</li>
                      <li>01.01.23 - 31.12.23: 3,5 HUF</li>
                      <li>01.10.22 - 31.12.22: 3,5 HUF</li>
                      <li>01.07.22 - 30.09.22: 3,5 HUF</li>
                      <li>01.04.22 - 30.06.22: 3,5 HUF</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel4" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagES}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      ES
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,049
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    Sinds 01.01.2019 zijn de compensatie vergoedingen per regio
                    in Spanje gelijkgesteld aan elkaar. Hierdoor krijgt u de
                    accijns op dieselolie terug met € 0,048 + het hoofdtarief
                    0,001. In totaal wordt 0,049 euro per liter terugbetaald.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel5" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagSL}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      SI
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,12878
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.11.24 - 30.10.24: € 0,12878</li>
                      <li>01.10.24 - 31.10.24: € 0,12878</li>
                      <li>01.09.24 - 30.09.24: € 0,13505</li>
                      <li>01.08.24 - 31.08.24: € 0,13822</li>
                      <li>01.07.24 - 31.07.24: € 0,09697</li>
                      <li>01.06.24 - 30.06.24: € 0,09538</li>
                      <li>01.05.24 - 31.05.24: € 0,09538</li>
                      <li>01.04.24 - 30.04.24: € 0,09538</li>
                      <li>01.03.24 - 31.03.24: € 0,08377</li>
                      <li>01.02.24 - 29.02.24: € 0,06267</li>
                      <li>01.01.24 - 31.01.24: € 0,06267</li>
                      <li>01.12.23 - 31.12.23: € 0,06654</li>
                      <li>01.11.23 - 30.11.23: € 0,09267</li>
                      <li>01.10.23 - 31.10.23: € 0,06798</li>
                      <li>01.09.23 - 30.09.23: € 0,05277</li>
                      <li>01.08.23 - 31.08.23: € 0,09012</li>
                      <li>01.07.23 - 31.07.23: € 0,12398</li>
                      <li>01.06.23 - 30.06.23: € 0,11322</li>
                      <li>01.05.23 - 31.05.23: € 0,11322</li>
                      <li>01.04.23 - 30.04.23: € 0,09674</li>
                      <li>01.03.23 - 31.03.23: € 0,07843</li>
                      <li>01.02.23 - 28.02.23: € 0,03073</li>
                      <li>01.01.23 - 31.01.23: € 0,00093</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel6" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagHR}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      HR
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,0761
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.08.24 - heden  € 0,0761</li>
                      <li>01.08.23 - 31.07.24  € 0,0530</li>
                      <li>01.02.23 - 31.07.23  € 0,0230</li>
                      <li>01.01.23 - 31.01.23: € 0,0761</li>
                      <li>01.07.22 - 31.12.22: € 0,0246</li>
                      <li>01.04.22 - 30.06.22: € 0,0512</li>
                      <li>01.01.22 - 31.03.22: € 0,0777</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
                sx={{ m: "auto", mt: 1, maxWidth: "699px" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel7" ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                  display="flex"
                  sx={{ boxShadow: 3 }}
                >
                  <Stack display="flex" direction="row">
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={vlagIT}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />

                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "Century Gothic",
                      }}
                    >
                      IT
                    </Typography>
                  </Stack>

                  <Stack
                    position="absolute"
                    sx={{ right: { xs: "10%", md: "14%", lg: "12%" } }}
                    display="flex"
                    flexDirection="right"
                    direction="row"
                    justifyContent="right"
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: { xs: 8, md: 8, lg: 15 },
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        width: { sx: "100px", lg: "175px" },
                      }}
                    >
                      0,214180
                    </Typography>
                    <Typography
                      sx={{
                        mt: { xs: 1, md: 0.5, lg: 0.5, xl: 0 },
                        ml: 2,
                        textAlign: "left",
                        color: "#2B5BAA",
                        fontSize: { xs: "24px", md: "29px", xl: "29px" },
                        lineHeight: {
                          xs: "22.8px",
                          md: "28.8px",
                          xl: "34.8px",
                        },
                        fontFamily: "HelveticaNeue-Light",
                        minWidth: { xs: 80, md: 100, lg: 150 },
                      }}
                    >
                      € / liter
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "18px", md: "21px", xl: "24px" },
                      lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                      letterSpacing: 0,
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <ul>
                      <li>01.07.24 - 30.09.24: € 0,21418</li>
                      <li>01.04.24 - 30.06.24: € 0,21418</li>
                      <li>01.01.24 - 31.03.24: € 0,21418</li>
                      <li>01.10.23 - 31.12.23: € 0,21418</li>
                      <li>01.07.23 - 30.09.23: € 0,21418</li>
                      <li>01.04.23 - 30.06.23: € 0,21418</li>
                      <li>01.01.23 - 31.03.23: € 0,21418</li>
                      <li>01.12.22 - 31.12.22: € 0,06418</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ExciseRatesForm;
