import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle60 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Mobiele grenscontroles in Nederland 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage60.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                21-11-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Koninklijke Marechaussee start op {" "}
            <span style={{color: "#2b5baa"}}>
            maandag 9 december{" "}
            </span>
            met de door het kabinet aangekondigde grenscontroles, aldus asielminister Marjolein Faber. Deze tijdelijke maatregel, die{" "}
            <span style={{color: "#2b5baa"}}>
            zes maanden zal duren
            </span>
            , is gericht op het tegengaan van{" "}
            <span style={{color: "#9c29ff"}}>
            "irreguliere migratie en migrantensmokkel"
            </span>
            . Normaal gesproken wordt in Nederland al steekproefsgewijs gecontroleerd aan de grens. Nu wordt dat dus  {" "}
            <span style={{color: "#9c29ff"}}>
            uitgebreid{" "}
            </span>
            met controles op{" "}
            <span style={{color: "#9c29ff"}}>
            vaste plaatsen. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De nieuwe maatregel veroorzaakt direct zorgen binnen de transport- en logistieke sector, die waarschuwt voor {" "}
            <span style={{color: "#2b5baa"}}>
            aanzienlijke economische gevolgen
            </span>
            . Minister Faber reageerde hierop met de verklaring: "De grenscontroles worden zo ingericht dat het {" "}
            <span style={{color: "#2b5baa"}}>
            economische verkeer{" "}
            </span>
            en woon-werkverkeer in de grensregio’s  {" "}
            <span style={{color: "#2b5baa"}}>
            zo min mogelijk hinder{" "}         
            </span>
            ondervinden. We staan hierover in contact met de grensregio’s." Toch blijft er{" "}
            <span style={{color: "#9c29ff"}}>
            onzekerheid{" "}         
            </span>
            bestaan over een volledig hinderloze grensovergang voor de professionele sector. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Door de uitbreiding van mobiele grenscontroles met vaste controlepunten moeten internationale transporteurs rekening houden met  {" "}
            <span style={{color: "#9c29ff"}}>
            langere wachttijden{" "}
            </span>
            bij grensovergangen. Het is dus van belang om{" "}
            <span style={{color: "#2b5baa"}}>
            flexibel{" "}
            </span>
            om te gaan met de{" "}
            <span style={{color: "#2b5baa"}}>
            routeplanning{" "}            
            </span>
            om zoveel mogelijk vertraging te voorkomen. Een snelle Google zoekopdracht voor vertrek kan al veel inzicht geven over de situatie bij de desbetreffende grensovergang. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle60;
